<template>
  <div v-show="isVisible" id="banner-msg" class="banner-message">
    <div class="close" @click="isVisible = false">
      <img src="~/assets/imgs/header-cross.png" alt="" loading="lazy" />
    </div>
    <div class="content-container">
      <img class="hide-on-mobile discount-img" src="~/assets/imgs/banner-discount.png" loading="lazy" />
      <div class="content" @click="goToOffers">
        Code {{ promoCode }} : Disponible 24h
        <br />
        <b>-50% sur l'offre sans engagement !</b>
      </div>
    </div>
  </div>
</template>

<script>
import { useNotificationStore } from '../stores/notification'
import CouponNotification from './Notifications/CouponNotification'

export default {
  data() {
    return {
      isVisible: true,
    }
  },
  computed: {
    promoCode() {
      return this.$config.public.discountCode
    },
  },
  methods: {
    async goToOffers() {
      if (this.$config.public.discountCode.length) {
        const paymentApi = usePaymentApi()
        const returnData = await paymentApi.setPromoCode(this.$config.public.discountCode)

        if (returnData && returnData.valid) {
          useNotificationStore().pushUnique(shallowRef(CouponNotification))
        }
      }
      this.$router.push('/offres')
    },
  },
}
</script>

<style lang="less" scoped>
.banner-message {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 50px;
  color: #171129;
  background-color: #ffaa68;
  margin-bottom: 10px;
  box-shadow: 0px 11px 14px rgba(0, 0, 0, 0.08);

  .close {
    cursor: pointer;
    margin-right: 5px;
    img {
      width: 15px;
      height: 16px;
    }
  }

  .content-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 0px;

    .content {
      cursor: pointer;
      font-size: 14px;
      text-align: center;
      b {
        text-decoration: underline;
      }
    }
  }

  @media @bp-desktop {
    margin-bottom: 0px;

    .close {
      margin-right: 25px;
      img {
        width: 19px;
        height: 20px;
      }
    }

    .content-container {
      flex: 1;
      padding: 5px 0;

      .discount-img {
        width: 52px;
        height: 43px;
        margin-right: 10px;
      }

      .content {
        text-decoration: none;
        font-size: 16px;
      }
    }
  }
}
</style>
